.btn-transparent {
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 0px !important;
  &:hover {
    background-color: #fff !important;
    color: #111 !important;
  }
  .font-white {
    &:hover {
      color: #111 !important;
    }
  }
  border: 1px solid #fff !important;
  text-decoration: none !important;
}

.base-btn {
  padding: 1% 10% !important;
  border-radius: 0px !important;
}

* {
  // font-family: Roboto;
  // font-family: 'Ubuntu', sans-serif;
  font-family: "Work Sans", sans-serif;
  // font-family: 'Raleway', sans-serif;
}

.mt-10 {
  margin-top: 6rem !important;
}

.minHeight-500 {
  min-height: 500px;
}

.padding-top-50 {
  padding-top: 50px;
}
@import "forms.scss";
@import "nav.scss";
@import "home.scss";
@import "product.scss";

.font-white {
  color: white !important;
  &:hover {
    color: white;
    text-decoration: none;
  }
  a:link {
    color: white;
    text-decoration: none;
  }
  a:link {
    color: white;
    text-decoration: none;
  }

  /* visited link */
  a:visited {
    color: white;
    text-decoration: none;
  }

  /* mouse over link */
  a:hover {
    color: white;
    text-decoration: none;
  }

  /* selected link */
  a:active {
    color: white;
    text-decoration: none;
  }
}

.full-loader {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  min-width: 100%;
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
}

.flex1 {
  flex: 1;
}

.padded-header-section {
  background: #2c8bec;
  min-height: 300px;
}

.full-wealth {
  width: 100%;
}


.margin-top-30 {
  margin-top: 30px !important;
}