.productCarousel {
  img {
    margin: 10px;
    height: 100%;
  }
  .selectedProductImage {
    border: 2px solid yellow;
  }
}

.carouselDisplay {
  img {
    margin: 10px;
    height: auto;
  }
}
.productlist {
  min-height: 500px;
  .card {
    border-radius: 0px;
  }
  .slick-arrow::before {
    color: black;
    font-size: 30px;
  }
  .slick-prev {
    left: -25px;
  }

  .slick-dots li button:before {
    font-size: large;
  }

  .slick-dots {
    position: absolute;
    bottom: -45px;
    display: block;
    width: 100%;
    padding: 10px;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .productMD {
    height: 100%;
    overflow: hidden;
    .productInfo {
      text-align: left;
      margin-top: -187px;
      color: #fff;
      /* left: 30px; */
      padding-left: 30px;
      z-index: 5000;
    }
  }

  /* [2] Transition property for smooth transformation of images */
  .productMD img {
    transition: transform 0.5s ease;
    z-index: 1000;
    min-height: 325px;
  }

  .productMD {
    a {
      width: 100%;
      text-decoration: none;
    }
    @media screen {
    }
  }
  /* [3] Finally, transforming the image when container gets hovered */
  .productMD:hover img {
    transform: scale(1.2);
  }
}

.dummyImageBackground {
  min-height: 325px;
  background-color: #1e1e1e;
}

.mainProductList {
  h1 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.1rem;
  }
}

.accessory-carousal-card {
  img {
    min-height: 300px;
    max-height: 550px;
  }
}

.productCartTable {
  td {
    font-size: 16px !important;
  }
  .innerHeader {
    th {
      background-color: #282726;
      color: white;
    }
  }
}
