.nav-style {
  a {
    // color: #fff !important;
    // font-size: 16px;
    position: relative;
    display: block;
    font-size: 16px;
    color: #ffffff !important;
    line-height: 30px;
    font-weight: 500;
    opacity: 1;
    text-transform: capitalize;
    padding: 30px 0px 15px;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    font-family: "Poppins", sans-serif;
  }
  a:after {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 10px;
    width: 23px;
    height: 1px;
    opacity: 0;
    /* background-color: #08ae8d; */
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
  }

  .dropdown-menu-right a {
    position: relative;
    display: block;
    padding: 9px 25px;
    line-height: 24px;
    font-weight: 400;
    font-size: 15px;
    color: #242424 !important;
    text-align: left;
    padding-right: 20px;
    text-transform: capitalize;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    font-family: "Poppins", sans-serif;
  }
}

.navbar-brand {
  img {
    width: 200px;
    margin-top: -12px;
  }
}

.hero-images {
  width: 100%;

  .hero-backgroud {
    background-origin: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
}
.hero-background {
  // background-image: url('../images/home-dark.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 600px;
  background-color: #ddd;
  text-align: center;
  // .carousel {
  //   position: relative;
  //   top: 30%;
  // }
  h2 {
    color: #fff !important;
    font-size: 3rem;
  }
  .hero-content {
    position: relative;
    top: 40%;
    color: white !important;
  }
  .hero1 {
    background-image: linear-gradient(
        rgba(20, 20, 20, 0.4),
        rgba(20, 20, 20, 0.4)
      ),
      url("../images/hero1.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 600px;
    width: 100%;
  }
  .hero2 {
    background-image: linear-gradient(
        rgba(20, 20, 20, 0.4),
        rgba(20, 20, 20, 0.4)
      ),
      url("../images/hero2.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 600px;
    width: 100%;
  }
  .hero3 {
    background-image: linear-gradient(
        rgba(20, 20, 20, 0.4),
        rgba(20, 20, 20, 0.4)
      ),
      url("../images/hero3.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 600px;
    width: 100%;
  }
}
.slick-initialized {
  width: 100% !important;
  // position: absolute;
  .slick-prev {
    left: 0;
  }
}
.slick-list {
  padding: 0px !important;
}

.slideDiv {
  color: #fff;
  text-shadow: 1px 0px 0px #111;
  min-height: 200px;
  position: relative;
  top: 30%;
}

.offer-section {
  img {
    height: 100%;
    width: 100%;
  }
}

.footer {
  background-color: #1e1e1e;
  min-height: 200px;
  color: #8b8b8b;
  bottom: 0px;
  // position: absolute;
  a {
    color: #8b8b8b;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 15px;
    :hover,
    :active {
      text-decoration: none !important;
    }
  }
  hr {
    border-color: #8a8a8a;
  }
}

hr {
  border-color: #ffffff !important;
}
.catalogue {
  background-image: linear-gradient(
      rgba(20, 20, 20, 0.7),
      rgba(20, 20, 20, 0.7)
    ),
    url("../images/brochure-image.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 500px;
  width: 100%;
  color: white;
  .form-control {
    border-left: transparent;
    border-top: transparent;
    border-right: transparent;
    background-color: transparent;
    border-radius: 0px;
    color: #fff;
    &:active,
    &:hover,
    &:focus {
      border-left: transparent;
      border-top: transparent;
      border-right: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: transparent;
      color: #fff;

      outline: 0;
      // border-color: transparent;
    }
  }
  .form-group {
    padding: 10px;
  }

  .catalogueContent {
    margin: "auto";
    position: relative;
    top: 45%;
  }
}

.productlist {
  .slick-initialized {
    position: relative !important;
  }
  .productImage {
    max-height: 400px;
  }
}

.productlist-hero {
  background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)),
    url("../images/product-list-header.webp");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 640px;
  width: 100%;
}

.productImage {
  max-height: 400px;
}

.contactusHero {
  background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)),
    url("../images/contact-us-header.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 500px;
  width: 100%;
  h1 {
    font-size: 4.5rem;
    color: white;
    top: 40%;
    position: relative;
  }
}

.aboutusHero {
  background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)),
    url("../images/ab3.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 600px;
  width: 100%;
}
.processHero {
  background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)),
    url("../images/process.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 500px;
  width: 100%;
}

.process-hero-vid {
  position: relative;
  height: 100vh;
  overflow: hidden;

  video {
    background: url("../images/process.jpg") no-repeat;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
  }

  .content {
    position: absolute;
    padding: 20px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
  }
}

.headerHero {
  text-align: center;
  h1 {
    font-size: 3.5rem;
    color: white;
    top: 40%;
    position: relative;
  }
}
.privacyPolicyHero {
  background-image: linear-gradient(
      rgba(20, 20, 20, 0.5),
      rgba(20, 20, 20, 0.5)
    ),
    url("../images/about-us.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 500px;
  width: 100%;
}

.contact-us {
  .fas {
    padding: 5px 30px;
    font-size: 30px;
  }
  .text {
    color: #797979;
    font-size: 16px;
    margin-bottom: 30px;
    text-decoration: none;
  }
}

.home-fly-bridge {
  background-image: linear-gradient(
      rgba(20, 20, 20, 0.5),
      rgba(20, 20, 20, 0.5)
    ),
    url("../images/home/flybridge-home.webp");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  // background-color: #23252a;
  height: 100vh;
  width: 100%;
  .pp-tableCell {
    vertical-align: text-top;
    .bottom {
      position: absolute;
      bottom: 50px;
    }
    .top {
      position: absolute !important;
      top: 80px !important;
    }
  }
}

.home-cruise-liner {
  // background-image: linear-gradient(
  //     rgba(20, 20, 20, 0.5),
  //     rgba(20, 20, 20, 0.5)
  //   ),
  //   url("../images/home-3-bg.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: #14181c;
  height: 100vh;
  width: 100%;
  .pp-tableCell {
    // vertical-align: bottom;
    vertical-align: text-top;
    .bottom {
      position: absolute;
      bottom: 50px;
    }
    .top {
      position: absolute !important;
      top: 80px !important;
    }
  }
}

.home-pebble {
  // background-image: linear-gradient(
  //     rgba(20, 20, 20, 0.5),
  //     rgba(20, 20, 20, 0.5)
  //   ),
  //   url("../images/home-2-bg.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: #1f1619;
  height: 100vh;
  width: 100%;
  .pp-tableCell {
    vertical-align: text-top;
    .bottom {
      position: absolute;
      bottom: 50px;
    }
    // .top {
    //   position: absolute !important;
    //   top: 80px !important;
    // }
  }
}

.home-footer {
  height: 100vh;
  width: 100%;
  background-color: #1e1e1e;
}

.video-overlay {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;

  h1 {
    color: white;
    text-align: center;
    font-size: 4.5rem;
    vertical-align: middle;
  }
}

#pagepiling {
  .productName {
    color: white;
    font-size: 80px;
    margin-top: 80px;
    justify-content: "center";
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .productDescription {
    color: white;
    font-size: 25px;
    margin-top: 30px;
    justify-content: center;
  }
  @media (max-width: 576px) {
    .productName {
      font-size: 50px;
      top: 50px;
      margin-top: 20px;
    }
    .productDescription {
      color: white;
      font-size: 16px;
    }
  }
}

.fullWidth {
  min-width: 100%;
}

.fullHeight {
  min-height: 100vh;
}

.alignCenter {
  align-self: center;
}

.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.home-piano {
  // background-image: linear-gradient(
  //     rgba(20, 20, 20, 0.5),
  //     rgba(20, 20, 20, 0.5)
  //   ),
  //   url("../images/home-1-bg.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  // background-attachment: fixed;

  background-color: #8b705b;
  height: 100vh;
  width: 100%;
  .pp-tableCell {
    vertical-align: text-top;
    .bottom {
      position: absolute;
      bottom: 50px;
    }
    .top {
      position: absolute !important;
      top: 80px !important;
    }
  }
}

.video-page {
  background-color: black;
  height: 100vh;
}
.video-container {
  position: "relative";
  width: 100%;
  padding-bottom: 56.25%;
}
@media screen and (max-width: 768px) {
  .main-text {
    margin-top: 70px;
  }
  .sub-text {
    margin-top: 30px;
    margin-bottom: 50px;
    pointer-events: all;
  }
}

@media screen and (min-width: 769px) {
  .main-text {
    margin-top: 20px;
    h3 {
      margin-right: -70px;
    }
  }
  .sub-text {
    margin-top: 10px;
    pointer-events: all;
  }
}

.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform 0.5s ease-in-out;
}

.img-hover-zoom:hover img {
  transform: scale(1.2);
}

.new-home {
  background-repeat: no-repeat;
  background-size: cover;
  // background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5));

  // background-attachment: fixed;
  overflow: scroll;
  // background-color: #14181c;
  height: "100%";

  // width: 100%;
  // .row {
  .section1 {
    background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)),
      url("../images/home/1.webp");

    // background-image:url("../images/home/1.webp") ;
    background-position: bottom;
    min-height: 100vh;
    background-size: contain;
  }
  .section2 {
    background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)),
      url("../images/home/2.webp");

    // background-image: url("../images/home/2.webp");
    background-position: bottom;

    min-height: 100vh;
    background-size: contain;
  }
  .section3 {
    background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)),
      url("../images/home/3.webp");

    // background-image: url("../images/home/3.webp");
    background-size: contain;
    background-position: bottom;

    min-height: 100vh;
  }
  .section4 {
    background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)),
      url("../images/home/4.webp");

    // background-image: url("../images/home/4.webp");
    min-height: 100vh;
    background-position: bottom;

    background-size: contain;
  }
  // }
}

.fullscreen-image {
  min-height: 100vh; /* Set the height of the element to full viewport height */
  background-size: cover; /* Scale the image to cover the entire element */
  background-position: center; /* Center the image within the element */
  transition: opacity 0.5s ease-in-out; /* Add a smooth transition effect */
  padding: 90px 0px 100px;
  background-position: right top;
}

/* Add classes to individual fullscreen-image elements */
.image-1 {
  background-image: url("../images/home/1.webp");
}

.image-2 {
  background-image: url("../images/home/2.webp");
}

.image-3 {
  background-image: url("../images/home/3.webp");
}

/* Hide all fullscreen-image elements by default */

.home-details {
  background-image: url("../images/home/3.webp");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 600px;
  position: relative;
  padding: 90px 0px 100px;
  background-position: right top;
  margin-top: 50px;
  h2 {
    color: #fff;
    span {
      font-weight: 800;
    }
  }
  .text {
    color: #fff;
  }
}

.home-details2 {
  background-image: url("../images/home/2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 600px;
  position: relative;
  padding: 90px 0px 100px;
  background-position: right top;
  margin-top: 50px;
  h2 {
    color: #fff;
    span {
      font-weight: 800;
    }
  }
  .text {
    color: #fff;
  }
}

.home-details3 {
  background-image: url("../images/home/3.webp");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 600px;
  position: relative;
  padding: 90px 0px 100px;
  background-position: right top;
  margin-top: 50px;
  h2 {
    color: #fff;
    span {
      font-weight: 800;
    }
  }
  .text {
    color: #fff;
  }
}

.headingText {
  position: relative;
  top: 30;
  z-index: 500;
  color: #111;
}

.large-text {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  text-align: center;
  z-index: 999;
  color: #fff;
  text-transform: uppercase;
}
