.form {
  .form-control {
    border-left: transparent;
    border-top: transparent;
    border-right: transparent;
    border-radius: 0px;
    &:active,
    &:hover,
    &:focus {
      border-left: transparent;
      border-top: transparent;
      border-right: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0;
      // border-color: transparent;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: red;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: red;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: red;
    }
  }
  .form-group {
    padding: 10px;
  }
  .form-error {
    color: red;
  }
}

.contactusSubmit {
  border-radius: 0px !important;
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.authBackground {
  background-image: linear-gradient(
      rgba(20, 20, 20, 0.7),
      rgba(20, 20, 20, 0.7)
    ),
    url("../images/home/home1.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  min-width: 100vw;
}


.formContainer {
  align-items: center;
  min-height: 100vh;
  align-content: center;
  vertical-align: middle;
  display: flex;
  padding-top: 90px;
  padding-bottom: 90px;
  .formArea {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-inline: 30px;
  }
}