// .navbar-collapse {
//     /* flex-basis: 100%; */
//     /* flex-grow: 1; */
//     display: block;
//     /* align-items: center; */
//     position: fixed;
//     top: 10%;
//     z-index: 10000000000;
//     background-color: blue;
//     width: 100%;
//     padding: 0;
//     left: 0;
//     right: 0;
//     height: 100%;
//     font-size: 25px;
//     text-align: center;
// }

.navbar {
// border-bottom: 2px solid blue;
  .navbar-toggler-icon {
    width: 0.8em;
    height: 0.8em;
  }
  a {
    font-size: 1rem;
    text-transform: uppercase;
    padding-left: 40px !important;
  }
  @media (max-width: 576px) {
    a {
      padding-left: 0px !important;
    }
  }

  img {
    width: 65px !important;
    margin-top: -5px !important;
    padding-right: 10px;
  }
}

.dark-header {
  background-color: transparent !important;
  color: #fff !important;
  a {
    color: #fff !important;
  }
  .navbar-nav {
    background-color: #1e1e1e !important;
  }
}

.darker-header  {
  background-color: #1e1e1e;
  color: #fff;
}

.badgeIcon {
  content: "100";
  position: absolute;
  background: #ff4545;
  height: 24px;
  width: 24px;
  top: 6px;
  right: 45px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  border-radius: 50%;
  color: white;
  border: 1px solid #ff4545;
  z-index: -5;
}
